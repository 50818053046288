import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, useLocation, withRouter } from "react-router-dom";
import Loading from "./components/Loading/Loading";
import EmailConfirmation from "./pages/EmailConfirmation/EmailConfirmation";
import Landing from "./pages/Landing/Landing";
import Login from "./pages/Login/Login";
import ProductInfo from "./pages/ProductInfo/ProductInfo";
import Shop from "./pages/Shop/Shop";
import ShoppingCart from "./pages/ShoppingCart/ShoppingCart";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction";
import UserStorePage from "./pages/UserStorePage/UserStorePage";
import areaManagerImg from "./pages/images/areamanager/areamanager-bg.jpg";
import businessPartnerImg from "./pages/images/businesspartner/businesspartner-bg.jpg";
import salePromoterImg from "./pages/images/salepromoter/salepromoter-bg.jpg";
import specialCustomerImg from "./pages/images/specialcustomer/specialcustomer-bg.jpg";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import IntegrationService from "./service/IntegrationService";
import GetUserInfoService from "./service/GetUserInfoService";
import TagManager from "react-gtm-module";

const UserDashboardLazy = lazy(() => import("./pages/UserDashboard/UserDashboard"));
const RegisterUserLazy = lazy(() => import("./pages/RegisterUser/RegisterUser"));

const integrationService = new IntegrationService();
const getUserInfoService = new GetUserInfoService();

const AppWrapper = () => {
  let location = useLocation();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  // eslint-disable-next-line no-unused-vars
  const [latitude, setLatitude] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [longitude, setLongitude] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [precision, setPrecision] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [mode, setMode] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isMobile, setIsMobile] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [deviceToken, setDeviceToken] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [platform, setPlatform] = useState("");

  const latitudeSession = JSON.parse(localStorage.getItem("__extraconomy_lat__")); // Get latitude from session storage.
  const longitudeSession = JSON.parse(localStorage.getItem("__extraconomy_long__")); // Get longitude from session storage.
  const precissionSession = JSON.parse(localStorage.getItem("__extraconomy_precision__"));
  const deviceTokenSession = JSON.parse(localStorage.getItem("__extraconomy_devicetoken__"));

  const sessionIsMobile = JSON.parse(localStorage.getItem("__extraconomy_ismobile__")); // mobile = true;
  const sessionMode = JSON.parse(localStorage.getItem("__extraconomy_mode__")); // GPS or NETWORK
  const platformSession = JSON.parse(localStorage.getItem("__extraconomy_platform__")); // Platform: IOS or android

  useEffect(() => {
    window.prerenderReady = false;
  }, []);

  // save session on a state, so the page can be reloaded.
  useEffect(() => {
    setLatitude(JSON.parse(localStorage.getItem("__extraconomy_lat__")));
    setLongitude(JSON.parse(localStorage.getItem("__extraconomy_long__")));
    setPrecision(JSON.parse(localStorage.getItem("__extraconomy_precision__")));
    setMode(JSON.parse(localStorage.getItem("__extraconomy_mode__")));
    setDeviceToken(JSON.parse(localStorage.getItem("__extraconomy_devicetoken__")));
    setPlatform(JSON.parse(localStorage.getItem("__extraconomy_platform__")));

    if (!JSON.parse(localStorage.getItem("__extraconomy_ismobile__"))) {
      localStorage.setItem("__extraconomy_ismobile__", false);
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [
    latitudeSession,
    longitudeSession,
    precissionSession,
    sessionMode,
    sessionIsMobile,
    deviceTokenSession,
    platformSession,
  ]);

  useEffect(() => {
    if (
      sessionIsMobile &&
      !localStorage.getItem("__extraconomy_token") &&
      !localStorage.getItem("__extraconomy_username") &&
      window.localStorage.getItem("__extraconomy_token") &&
      window.localStorage.getItem("__extraconomy_username")
    ) {
      const token = window.localStorage.getItem("__extraconomy_token");
      const username = window.localStorage.getItem("__extraconomy_username");

      localStorage.setItem("__extraconomy_token", token);
      localStorage.setItem("__extraconomy_username", username);

      // initially, the app redirected the user to the dashboard when logged in.
      // This behavior is not desired and the above line was commented.
      // window.location.assign('/dashboard');
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          sendLinktoWebview: true,
        })
      );
    }
  }, [sessionIsMobile]);

  useEffect(() => {
    if (
      !(location.pathname.includes("/shop") && query.get("search")) &&
      localStorage.getItem("__extraconomy_search")
    ) {
      localStorage.removeItem("__extraconomy_search");
    }
  }, [location.pathname, query]);

  useEffect(() => {
    integrationService
      .getPixel()
      .then((res) => {
        const head = document.querySelector("head");
        const script = document.createElement("script");
        script.text = `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', ${res.result.facebook_pixel_id}); fbq('track', 'PageView'); `;
        head.appendChild(script);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location.pathname]);

  useEffect(() => {
    async function getUserInfo() {
      let info = await getUserInfoService.getUserInfo(
        localStorage.getItem("__extraconomy_token"),
        localStorage.getItem("__extraconomy_username")
      );
      sendPageview(info.result.user);
    }
    async function sendPageview(user) {
      let ip = await integrationService.getUserIp();
      let date = new Date();
      let payload = {
        eventData: {
          currency: "",
          value: 0,
        },
        eventName: "PageView",
        eventTime: Math.floor(date.getTime() / 1000),
        userData: {
          email: user ? user.email : "",
          ipAddress: ip.data.ip,
          phone: "",
          userAgent: window.navigator.userAgent,
        },
      };
      let res = await integrationService.sendEvent(payload);
      return res;
    }
    localStorage.getItem("__extraconomy_token") ? getUserInfo() : sendPageview(null);
  }, [location.pathname]);

  useEffect(() => {
    async function getGtm() {
      let res = await integrationService.getGtm();
      return res;
    }
    getGtm().then((res) => {
      if (!res.result?.gtmcode) {
        console.log(res)
        return;
      }
      try {
        const tagMangerArgs = {
          gtmId: res.result.gtmcode,
        };
        TagManager.initialize(tagMangerArgs);
      } catch (err) {
        console.log(err);
      }
    });
  }, []);

  switch (location.pathname) {
    case "/login":
      return <Route path="/login" component={Login} />;
    case "/emailconfirmation":
      return (
        <Route path="/emailconfirmation">
          <EmailConfirmation
            message={query.get("message")}
            title={query.get("title")}
            type={query.get("type")}
          />
        </Route>
      );
    case "/orderconfirmation":
      return (
        <Route path="/orderConfirmation">
          <EmailConfirmation
            message={query.get("message")}
            title={query.get("title")}
            type={query.get("type")}
            url={query.get("url")}
          />
        </Route>
      );
    case "/businesspartner":
      return (
        <Route path="/businesspartner">
          <Suspense fallback={<Loading />}>
            <RegisterUserLazy
              img={businessPartnerImg}
              plan="businesspartner"
              link={query.get("link")}
            />
          </Suspense>
        </Route>
      );
    case "/areamanager":
      return (
        <Route path="/areamanager">
          <Suspense fallback={<Loading />}>
            <RegisterUserLazy img={areaManagerImg} plan="areamanager" link={query.get("link")} />
          </Suspense>
        </Route>
      );
    case "/specialcustomer":
      return (
        <Route path="/specialcustomer">
          <Suspense fallback={<Loading />}>
            <RegisterUserLazy
              img={specialCustomerImg}
              plan="specialcustomer"
              link={query.get("link")}
            />
          </Suspense>
        </Route>
      );
    case "/seller":
      return (
        <Route path="/seller">
          <Suspense fallback={<Loading />}>
            <RegisterUserLazy img={specialCustomerImg} plan="seller" link={query.get("link")} />
          </Suspense>
        </Route>
      );
    case "/salepromoter":
      return (
        <Route path="/salepromoter">
          <Suspense fallback={<Loading />}>
            <RegisterUserLazy img={salePromoterImg} plan="salepromoter" link={query.get("link")} />
          </Suspense>
        </Route>
      );
    case "/comingsoon":
      return <Route path="/comingsoon" component={UnderConstruction} />;
    case "/shop":
      return (
        <Route path="/shop">
          <Shop search={query.get("search")} />
        </Route>
      );
    case "/product":
      return (
        <Route path="/product">
          <ProductInfo id={query.get("id")} />
        </Route>
      );
    case "/store":
      return (
        <Route path="/store">
          <UserStorePage user={query.get("user")} />
        </Route>
      );
    case "/cart":
      return <Route path="/cart" component={ShoppingCart} />;
    case "/privacy":
      return <Route path="/privacy" component={PrivacyPolicy} />;
    case "/dashboard":
      return (
        <Suspense fallback={<Loading />}>
          <UserDashboardLazy />
        </Suspense>
      );
    case "/":
      let origin = window.location.origin.toString();
      if (
        origin === "https://business.extraconomy.com" ||
        origin === "https://apptest-business.extraconomy.com"
      ) {
        return <Route exact path="/" component={Landing} />;
      }
      return (
        <Route path="/">
          <Shop search={query.get("search")} />
        </Route>
      );
    default:
      let dashboardRoutes = [
        "dropshippingproduct",
        "editprofile",
        "salesdetails",
        "purchasesdetails",
        "newproduct",
        "products",
        "attributes",
        "favorites",
        "importproducts",
        "userstore",
        "dropshipping",
        "dashboard",
        "walletStatement",
        "withdrawintent",
        "extrapay",
        "extracoinStatement",
        "extracoinintents",
        "extracointransfer",
        "extrabonusStatement",
        "bonusStatement",
        "coupons",
        "sales",
        "purchases",
        "networkdirects",
        "networkall",
        "renewsubscription",
        "web3dashboard",
        "storesmap",
        "listareamanagers",
        "listbusinesspartners",
        "listspecialcustomers",
        "listsalepromoters",
        "listsellers",
        "listproducts",
        "listorders",
        "listwithdrawrequests",
        "listcategory",
        "newcategories",
        "listsubcategories",
        "newsubcategory",
        "listbrands",
        "newbrands",
        "listtypes",
        "newtypes",
        "address",
        "paidsubscriptions",
        "bangood",
        "metaads",
        "gtm",
      ];
      let path = location.pathname.split("/")[1];

      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "PageView",
      });
      if (dashboardRoutes.includes(path)) {
        return (
          <Suspense fallback={<Loading />}>
            <UserDashboardLazy />
          </Suspense>
        );
      }
      let slug = location.pathname.split("/")[1];
      return (
        <Suspense fallback={<Loading />}>
          <UserStorePage slug={slug} user={query.get("user")} />
        </Suspense>
      );
  }
};

export default withRouter(AppWrapper);
